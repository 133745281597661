<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "status": null
})

const visibility = _computed(() => statusVisibilities.find(v => v.value === __props.status.visibility)!)
</script>

<template>
  <CommonTooltip :content="$t(`visibility.${visibility.value}`)" placement="bottom">
    <div :class="visibility.icon" />
  </CommonTooltip>
</template>
