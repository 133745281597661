<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "status": null,
  "newer": null,
  "withAction": { type: Boolean, default: true }
})

const { translation } = useTranslation(__props.status, getLanguageCode())

const emojisObject = useEmojisFallback(() => __props.status.emojis)
const vnode = _computed(() => {
  if (!__props.status.content)
    return null
  const vnode = contentToVNode(__props.status.content, {
    emojis: emojisObject.value,
    mentions: 'mentions' in __props.status ? __props.status.mentions : undefined,
    markdown: true,
    collapseMentionLink: !!('inReplyToId' in __props.status && __props.status.inReplyToId),
    status: 'id' in __props.status ? __props.status : undefined,
    inReplyToStatus: __props.newer,
  })
  return vnode
})
</script>

<template>
  <div class="status-body" whitespace-pre-wrap break-words :class="{ 'with-action': withAction }" relative>
    <span
      v-if="status.content"
      class="content-rich line-compact" dir="auto"
      :lang="('language' in status && status.language) || undefined"
    >
      <component :is="vnode" />
    </span>
    <div v-else />
    <template v-if="translation.visible">
      <div my2 h-px border="b base" bg-base />
      <ContentRich v-if="translation.success" class="line-compact" :content="translation.text" :emojis="status.emojis" />
      <div v-else text-red-4>
        Error: {{ translation.error }}
      </div>
    </template>
  </div>
</template>

<style>
.status-body.with-action p {
  cursor: pointer;
}
</style>
