<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "status": null,
  "newer": null,
  "context": null
})

const isDM = _computed(() => __props.status.visibility === 'direct')
const isDetails = _computed(() => __props.context === 'details')

// Content Filter logic
const filterResult = _computed(() => __props.status.filtered?.length ? __props.status.filtered[0] : null)
const filter = _computed(() => filterResult.value?.filter)

const filterPhrase = _computed(() => filter.value?.title)
const isFiltered = _computed(() => __props.status.account.id !== currentUser.value?.account.id && filterPhrase.value && __props.context && __props.context !== 'details' && !!filter.value?.context.includes(__props.context))

// check spoiler text or media attachment
// needed to handle accounts that mark all their posts as sensitive
const hasSensitiveSpoilerOrMedia = _computed(() => __props.status.sensitive && (!!__props.status.spoilerText || !!__props.status.mediaAttachments.length))
</script>

<template>
  <div
    space-y-3
    :class="{
      'pt2 pb0.5 px3.5 bg-dm rounded-4 me--1': isDM,
      'ms--3.5 mt--1 ms--1': isDM && context !== 'details',
    }"
  >
    <StatusBody v-if="!isFiltered && status.sensitive && !status.spoilerText" :status="status" :newer="newer" :with-action="!isDetails" :class="isDetails ? 'text-xl' : ''" />
    <StatusSpoiler :enabled="hasSensitiveSpoilerOrMedia || isFiltered" :filter="isFiltered" :is-d-m="isDM">
      <template v-if="status.spoilerText" #spoiler>
        <p>{{ status.spoilerText }}</p>
      </template>
      <template v-else-if="filterPhrase" #spoiler>
        <p>{{ `${$t('status.filter_hidden_phrase')}: ${filterPhrase}` }}</p>
      </template>
      <StatusBody v-if="!status.sensitive || status.spoilerText" :status="status" :newer="newer" :with-action="!isDetails" :class="isDetails ? 'text-xl' : ''" />
      <StatusTranslation :status="status" />
      <StatusPoll v-if="status.poll" :status="status" />
      <StatusMedia
        v-if="status.mediaAttachments?.length"
        :status="status"
      />
      <StatusPreviewCard
        v-if="status.card"
        :card="status.card"
        :small-picture-only="status.mediaAttachments?.length > 0"
      />
      <StatusCard
        v-if="status.reblog"
        :status="status.reblog" border="~ rounded"
        :actions="false"
      />
      <div v-if="isDM" />
    </StatusSpoiler>
  </div>
</template>
