<script setup lang="ts">import { ref as _ref } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "status": null
})

const {
  toggle: _toggleTranslation,
  translation,
  enabled: isTranslationEnabled,
} = useTranslation(__props.status, getLanguageCode())
const preferenceHideTranslation = usePreferences('hideTranslation')

const showButton = computed(() => !preferenceHideTranslation.value && isTranslationEnabled)

let translating = _ref(false)
const toggleTranslation = async () => {
  translating.value = true
  try {
    await _toggleTranslation()
  }
  finally {
    translating.value = false
  }
}
</script>

<template>
  <div>
    <button
      v-if="showButton" p-0 flex="~ center" gap-2 text-sm
      :disabled="translating" disabled-bg-transparent btn-text class="disabled-text-$c-text-btn-disabled-deeper" @click="toggleTranslation"
    >
      <span v-if="translating" block animate-spin preserve-3d>
        <span block i-ri:loader-2-fill />
      </span>
      <div v-else i-ri:translate />
      {{ translation.visible ? $t('menu.show_untranslated') : $t('menu.translate_post') }}
    </button>
  </div>
</template>

<style scoped></style>
