<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "status": null,
  "isSelfReply": { type: Boolean, default: false }
})

const isSelf = _computed(() => __props.status.inReplyToAccountId === __props.status.account.id)
const account = isSelf.value ? computed(() => __props.status.account) : useAccountById(__props.status.inReplyToAccountId)
</script>

<template>
  <NuxtLink
    v-if="status.inReplyToId"
    flex="~ gap2" items-center h-auto text-sm text-secondary
    :to="getStatusInReplyToRoute(status)"
    :title="$t('status.replying_to', [account ? getDisplayName(account) : $t('status.someone')])"
    text-blue saturate-50 hover:saturate-100
  >
    <template v-if="isSelfReply">
      <div i-ri-discuss-line text-blue />
      <span>{{ $t('status.show_full_thread') }}</span>
    </template>
    <template v-else>
      <div i-ri-chat-1-line text-blue />
      <div ws-nowrap>
        <i18n-t keypath="status.replying_to" />
      </div>
      <template v-if="account">
        <AccountInlineInfo :account="account" :link="false" />
      </template>
      <template v-else>
        {{ $t('status.someone') }}
      </template>
    </template>
  </NuxtLink>
</template>
