<script setup lang="ts">import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

const props = defineProps({
  "status": null,
  "actions": { type: Boolean, default: true },
  "context": null,
  "hover": { type: Boolean,  },
  "faded": { type: Boolean,  },
  "older": null,
  "newer": null,
  "hasOlder": { type: Boolean,  },
  "hasNewer": { type: Boolean,  },
  "main": null
})

const userSettings = useUserSettings()

const status = _computed(() => {
  if (props.status.reblog && (!props.status.content || props.status.content === props.status.reblog.content))
    return props.status.reblog
  return props.status
})

// Use original status, avoid connecting a reblog
const directReply = _computed(() => props.hasNewer || (!!status.value.inReplyToId && (status.value.inReplyToId === props.newer?.id || status.value.inReplyToId === props.newer?.reblog?.id)))
// Use reblogged status, connect it to further replies
const connectReply = _computed(() => props.hasOlder || status.value.id === props.older?.inReplyToId || status.value.id === props.older?.reblog?.inReplyToId)
// Open a detailed status, the replies directly to it
const replyToMain = _computed(() => props.main && props.main.id === status.value.inReplyToId)

const rebloggedBy = _computed(() => props.status.reblog ? props.status.account : null)

const statusRoute = _computed(() => getStatusRoute(status.value))

const el = ref<HTMLElement>()
const router = useRouter()

function onclick(evt: MouseEvent | KeyboardEvent) {
  const path = evt.composedPath() as HTMLElement[]
  const el = path.find(el => ['A', 'BUTTON', 'IMG', 'VIDEO'].includes(el.tagName?.toUpperCase()))
  const text = window.getSelection()?.toString()
  if (!el && !text)
    go(evt)
}

function go(evt: MouseEvent | KeyboardEvent) {
  if (evt.metaKey || evt.ctrlKey) {
    window.open(statusRoute.value.href)
  }
  else {
    cacheStatus(status.value)
    router.push(statusRoute.value)
  }
}

const createdAt = useFormattedDateTime(status.value.createdAt)
const timeAgoOptions = useTimeAgoOptions(true)
const timeago = useTimeAgo(() => status.value.createdAt, timeAgoOptions)

const isSelfReply = _computed(() => status.value.inReplyToAccountId === status.value.account.id)
const collapseRebloggedBy = _computed(() => rebloggedBy.value?.id === status.value.account.id)
const isDM = _computed(() => status.value.visibility === 'direct')

const showUpperBorder = _computed(() => props.newer && !directReply.value)
const showReplyTo = _computed(() => !replyToMain.value && !directReply.value)
</script>

<template>
  <div
    :id="`status-${status.id}`"
    ref="el"
    relative flex="~ col gap1"
    p="b-2 is-3 ie-4"
    :class="{ 'hover:bg-active': hover }"
    tabindex="0"
    focus:outline-none focus-visible:ring="2 primary"
    :lang="status.language ?? undefined"
    @click="onclick"
    @keydown.enter="onclick"
  >
    
    <div :h="showUpperBorder ? '1px' : '0'" w-auto bg-border mb-1 />

    <slot name="meta">
      
      <template v-if="status.inReplyToAccountId">
        <StatusReplyingTo
          v-if="showReplyTo"
          m="is-5" p="t-1 is-5"
          :status="status"
          :is-self-reply="isSelfReply"
          :class="faded ? 'text-secondary-light' : ''"
        />
        <div flex="~ col gap-1" items-center pos="absolute top-0 inset-is-0" w="77px" z--1>
          <template v-if="showReplyTo">
            <div w="1px" h="0.5" border="x base" mt-3 />
            <div w="1px" h="0.5" border="x base" />
            <div w="1px" h="0.5" border="x base" />
          </template>
          <div w="1px" h-10 border="x base" />
        </div>
      </template>

      
      <div flex="~ col" justify-between>
        <div
          v-if="rebloggedBy && !collapseRebloggedBy"
          flex="~" items-center
          p="t-1 b-0.5 x-1px"
          relative text-secondary ws-nowrap
        >
          <div i-ri:repeat-fill me-46px text-green w-16px h-16px class="status-boosted" />
          <div absolute top-1 ms-24px w-32px h-32px rounded-full>
            <AccountHoverWrapper :account="rebloggedBy">
              <NuxtLink :to="getAccountRoute(rebloggedBy)">
                <AccountAvatar :account="rebloggedBy" />
              </NuxtLink>
            </AccountHoverWrapper>
          </div>
          <AccountInlineInfo font-bold :account="rebloggedBy" :avatar="false" text-sm />
        </div>
      </div>
    </slot>

    <div flex gap-3 :class="{ 'text-secondary': faded }">
      
      <div relative>
        <div v-if="collapseRebloggedBy" absolute flex items-center justify-center top--6px px-2px py-3px rounded-full bg-base>
          <div i-ri:repeat-fill text-green w-16px h-16px />
        </div>
        <AccountHoverWrapper :account="status.account">
          <NuxtLink :to="getAccountRoute(status.account)" rounded-full>
            <AccountBigAvatar :account="status.account" />
          </NuxtLink>
        </AccountHoverWrapper>

        <div v-if="connectReply" w-full h-full flex mt--3px justify-center>
          <div w-1px border="x base" />
        </div>
      </div>

      
      <div flex="~ col 1" min-w-0>
        
        <div flex items-center space-x-1>
          <AccountHoverWrapper :account="status.account">
            <StatusAccountDetails :account="status.account" />
          </AccountHoverWrapper>
          <div flex-auto />
          <div v-show="!userSettings.zenMode" text-sm text-secondary flex="~ row nowrap" hover:underline whitespace-nowrap>
            <AccountBotIndicator v-if="status.account.bot" me-2 />
            <div flex="~ gap1" items-center>
              <StatusVisibilityIndicator v-if="status.visibility !== 'public'" :status="status" />
              <div flex>
                <CommonTooltip :content="createdAt">
                  <NuxtLink :title="status.createdAt" :href="statusRoute.href" @click.prevent="go($event)">
                    <time text-sm ws-nowrap hover:underline :datetime="status.createdAt">
                      {{ timeago }}
                    </time>
                  </NuxtLink>
                </CommonTooltip>
                <StatusEditIndicator :status="status" inline />
              </div>
            </div>
          </div>
          <StatusActionsMore v-if="actions !== false" :status="status" me--2 />
        </div>

        
        <StatusContent :status="status" :newer="newer" :context="context" mb2 :class="{ 'mt-2 mb1': isDM }" />
        <StatusActions v-if="actions !== false" v-show="!userSettings.zenMode" :status="status" />
      </div>
    </div>
  </div>
</template>
